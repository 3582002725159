import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from 'hooks/useAuth';
import { blueGrey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'utils/appRoutes';
import { TAB_VALUES } from 'utils/constants';
import Logout from '@mui/icons-material/Logout';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import LockIcon from '@mui/icons-material/Lock';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';

const TopBar = ({ onOpen }) => {
  const { profile, logoutUser } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function goToPage(url, params) {
    handleClose();
    navigate(url, { state: params });
  }

  function onLogout() {
    logoutUser();
    navigate('/login');
  }

  const isUser = profile?.role === 'user';

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 10 }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            onClick={onOpen}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={handleMenu}
            color="inherit"
            sx={{ marginLeft: 'auto' }}
          >
            <Avatar sx={{ bgcolor: blueGrey[400] }}>
              {profile?.name?.[0]}
            </Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                goToPage(
                  isUser
                    ? appRoutes.USER_PROFILE_SETTINGS
                    : appRoutes.ADMIN_UPDATE_PROFILE,
                  { value: TAB_VALUES.PHONE_CHANGE }
                )
              }
            >
              <ListItemIcon>
                <PhoneLockedIcon fontSize="small" />
              </ListItemIcon>
              Change Phone
            </MenuItem>
            <MenuItem
              onClick={() =>
                goToPage(
                  isUser
                    ? appRoutes.USER_PROFILE_SETTINGS
                    : appRoutes.ADMIN_UPDATE_PROFILE,
                  { value: TAB_VALUES.PASS_CHANGE }
                )
              }
            >
              <ListItemIcon>
                <LockIcon fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem
              onClick={() =>
                goToPage(
                  isUser
                    ? appRoutes.PAYMENT_METHODS
                    : appRoutes.ADMIN_PAYMENT_METHODS,
                  { value: TAB_VALUES.PHONE_CHANGE }
                )
              }
            >
              <ListItemIcon>
                <CallMissedOutgoingIcon fontSize="small" />
              </ListItemIcon>
              My withdrawal Account
            </MenuItem>
            <MenuItem onClick={onLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
