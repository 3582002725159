import { LoadingSpinner } from 'components';
import GuestLayout from 'components/layouts/GuestLayout';
import { useAuth } from 'hooks/useAuth';
import {
  FourOhFour,
  LoginPage,
  UserHomePage,
  UserPaymentMethods,
  VerifyOTPPage,
  WelcomePage,
} from 'pages';
import AddUserPage from 'pages/admin-pages/AddUserPage';
import AdminComplainPage from 'pages/admin-pages/AdminComplainPage';
import AdminHomePage from 'pages/admin-pages/AdminHomePage';
import AdminMethodsPage from 'pages/admin-pages/AdminMethodsPage';
import AppSettingsPage from 'pages/admin-pages/AppSettingsPage';
import PendingDepositPage from 'pages/admin-pages/PendingDepositPage';
import PendingWithdrawPage from 'pages/admin-pages/PendingWithdrawPage';
import TodaysTransactionsPage from 'pages/admin-pages/TodaysTransactionsPage';
import UserDetailPage from 'pages/admin-pages/UserDetailPage';
import UsersListPage from 'pages/admin-pages/UsersListPage';
import AffiliateWithdrawPage from 'pages/user-pages/AffiliateWithdrawPage';
import ComplainPage from 'pages/user-pages/ComplainPage';
import { DepositNowPage } from 'pages/user-pages/DepositNowPage';
import HistoryPage from 'pages/user-pages/HistoryPage';
import ProfileSettingsPage from 'pages/user-pages/ProfileSettingsPage';
import WithdrawPage from 'pages/user-pages/WithdrawPage';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import appRoutes from 'utils/appRoutes';
import { getValue } from 'utils/storage';
import ProtectAdmin from './ProtectAdmin';
import ProtectStuff from './ProtectStuff';
import ProjectedUser from './ProtectUser';

const AppRouter = () => {
  const { setLoggedIn } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const getAuth = async () => {
    const hasToken = getValue('token');
    if (hasToken) {
      setLoggedIn();
    } else {
      navigate('/login');
    }
    setLoaded(true);
  };

  console.log({ loaded });
  useEffect(() => {
    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      <Route path="/" element={<GuestLayout />}>
        <Route path="home" element={<WelcomePage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="verify" element={<VerifyOTPPage />} />
      </Route>

      {/* User Routes */}
      <Route element={<ProjectedUser />}>
        <Route path={appRoutes.USER_HOME} element={<UserHomePage />} />
        <Route
          path={appRoutes.USER_PAYMENT_METHODS}
          element={<UserPaymentMethods />}
        />
        <Route path={appRoutes.DEPOSIT_NOW} element={<DepositNowPage />} />
        <Route path={appRoutes.HISTORY} element={<HistoryPage />} />
        <Route path={appRoutes.WITHDRAW_NOW} element={<WithdrawPage />} />
        <Route path={appRoutes.COMPLAIN} element={<ComplainPage />} />
        <Route
          path={appRoutes.USER_PROFILE_SETTINGS}
          element={<ProfileSettingsPage />}
        />
        <Route
          path={appRoutes.AFFILIATE_WITHDRAW}
          element={<AffiliateWithdrawPage />}
        />
      </Route>

      {/* Admin Routes */}
      <Route element={<ProtectAdmin />}>
        <Route path={appRoutes.ADMIN_DASHBOARD} element={<AdminHomePage />} />
        <Route path={appRoutes.ADMIN_USER_LIST} element={<UsersListPage />} />
        <Route path={appRoutes.ADMIN_ADD_USER} element={<AddUserPage />} />
        <Route
          path={appRoutes.ADMIN_TODAYS_TRANSACTIONS}
          element={<TodaysTransactionsPage />}
        />
        <Route
          path={appRoutes.ADMIN_USER_DETAILS}
          element={<UserDetailPage />}
        />
        <Route
          path={appRoutes.ADMIN_PENDING_DEPOSITS}
          element={<PendingDepositPage />}
        />
        <Route
          path={appRoutes.ADMIN_PENDING_WITHDRAWS}
          element={<PendingWithdrawPage />}
        />
        <Route
          path={appRoutes.ADMIN_PAYMENT_METHODS}
          element={<AdminMethodsPage />}
        />
        <Route
          path={appRoutes.ADMIN_COMPLAINS}
          element={<AdminComplainPage />}
        />
        <Route
          path={appRoutes.ADMIN_APP_SETTINGS}
          element={<AppSettingsPage />}
        />
        <Route
          path={appRoutes.ADMIN_UPDATE_PROFILE}
          element={<ProfileSettingsPage />}
        />
      </Route>

      {/* Stuff Routes */}

      <Route element={<ProtectStuff />}>
        <Route path={appRoutes.STUFF_DASHBOARD} element={<AdminHomePage />} />
        <Route
          path={appRoutes.STUFF_TODAYS_TRANSACTIONS}
          element={<TodaysTransactionsPage />}
        />
        <Route
          path={appRoutes.STUFF_PENDING_DEPOSITS}
          element={<PendingDepositPage />}
        />
        <Route
          path={appRoutes.STUFF_PENDING_WITHDRAWS}
          element={<PendingWithdrawPage />}
        />
        <Route
          path={appRoutes.STUFF_COMPLAINS}
          element={<AdminComplainPage />}
        />
        <Route
          path={appRoutes.STUFF_UPDATE_PROFILE}
          element={<ProfileSettingsPage />}
        />
      </Route>

      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
};

export default AppRouter;
