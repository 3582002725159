import { LoadingButton } from '@mui/lab';
import { Modal, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAdmin } from 'hooks/useAdmin';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ReferralBonusForm = ({ user }) => {
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  const { isLoading, updateUserData } = useAdmin();

  const [bonusPercent, setBonusPercent] = useState(user.bonusPercent || 0);
  const [referralCode, setReferralCode] = useState(user.referralCode || 0);
  const [ownCode, setOwnCode] = useState(user.ownCode || '');

  function handleSubmit() {
    if (!bonusPercent || !ownCode) {
      return toast.error('required filed');
    }

    updateUserData(
      {
        userId: user._id,
        bonusPercent,
        referralCode,
        ownCode,
      },
      () => {
        setIsVisible(false);
        navigate(-1);
      }
    );
  }

  return (
    <Stack mt={2}>
      <LoadingButton
        loading={isLoading}
        variant="outlined"
        onClick={() => setIsVisible(true)}
      >
        Change User Data
      </LoadingButton>
      <Modal open={isVisible} onClose={() => setIsVisible(false)}>
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography variant="h6" component="h2" mb={2}>
              Update User Data
            </Typography>
            <TextField
              fullWidth
              label="Bonus Percent"
              value={bonusPercent}
              onChange={(e) => setBonusPercent(e.target.valueAsNumber)}
              type="number"
            />
            <TextField
              fullWidth
              label="Referral Code"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            <TextField
              fullWidth
              label="Own Code"
              value={ownCode}
              onChange={(e) => setOwnCode(e.target.value)}
            />

            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit}
              variant="contained"
              fullWidth
              sx={{ my: 2 }}
            >
              Update
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default ReferralBonusForm;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
};
