import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppRouter } from 'router';
import 'react-datepicker/dist/react-datepicker.css';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
  backgroundColor: '#25D366',
};

const goTowhatsApp = () => {
  window.open('https://wa.me/+8801874256837', '_blank');
};

const App = () => {
  return (
    <BrowserRouter>
      <AppRouter />
      <ToastContainer />
      <Fab onClick={goTowhatsApp} style={fabStyle} aria-label="add">
        <WhatsAppIcon sx={{ color: '#fff' }} />
      </Fab>
    </BrowserRouter>
  );
};

export default App;
