export const USER_METHODS = [
  {
    name: 'bkash',
    title: 'bKash (Personal)',
  },
  {
    name: 'bkash-agent',
    title: 'bKash (Agent)',
  },
  {
    name: 'nagad',
    title: 'Nagad (Personal)',
  },
  {
    name: 'nagad-agent',
    title: 'Nagad (Agent)',
  },
  {
    name: 'rocket',
    title: 'Rocket (Personal)',
  },
  {
    name: 'rocket-agent',
    title: 'Rocket (Agent)',
  },
  {
    name: 'upay',
    title: 'Upay (Personal)',
  },
  {
    name: 'upay-agent',
    title: 'Upay (Agent)',
  },
  {
    name: 'city-bank',
    title: 'City Bank',
  },
  {
    name: 'dbbl',
    title: 'DDBL',
  },
  {
    name: 'local-agent',
    title: 'Local Agent',
  },
];

export const TAB_VALUES = {
  PASS_CHANGE: 1,
  PHONE_CHANGE: 2,
  NAME_CHANGE: 3,
};
