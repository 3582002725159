import { Avatar, Stack, Typography } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { blueGrey } from '@mui/material/colors';

const UserAvatar = () => {
  const { profile } = useAuth();
  return (
    <Stack
      alignItems="center"
      spacing={1}
      py={2}
      mb={2}
      sx={{ borderBottom: '1px solid rgba(1,1,1,0.25)' }}
    >
      <Avatar sx={{ bgcolor: blueGrey[500] }}>{profile?.name?.[0]}</Avatar>
      <Typography color="primary.main" fontWeight="medium" variant="body">
        {profile?.name}
      </Typography>
      <Typography color="gray" fontSize="14px" variant="body">
        ({profile?.userId})
      </Typography>
      {profile?.role === 'admin' && (
        <Typography color="red" fontSize={15}>
          Admin
        </Typography>
      )}
    </Stack>
  );
};

export default UserAvatar;
