import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useProfileSettings = create((set, get) => ({
  isLoading: false,

  updateProfile: async (formValue) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.put('/user', { ...formValue });
      if (response.status === 200) {
        toast.success('Profile updated!');
        set(() => ({ isLoading: false }));
      }
    } catch (error) {
      console.log('Error on get profile update', error);
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  updatePassword: async (fromValue) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.put('user/password', { ...fromValue });
      if (response.status === 200) {
        toast.success('Password changed!');
        set(() => ({ isLoading: false }));
      }
    } catch (error) {
      console.log('Invalid current password, Contact with Admin!', error);
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  updateUserPhone: async (formValue) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.put('user/change-phone', {
        ...formValue,
      });
      if (response.status === 200) {
        toast.success('Phone no. updated!');
        window.location('/user');
        set(() => ({ isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
