import { Container } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const GuestLayout = () => {
  const { isLoggedIn, role } = useAuth();

  if (isLoggedIn && role === 'admin') {
    return <Navigate to="/admin" />;
  }

  if (isLoggedIn && role === 'user') {
    return <Navigate to="/user" />;
  }

  if (isLoggedIn && role === 'stuff') {
    return <Navigate to="/stuff" />;
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: '100vh',
        padding: 2.5,
      }}
    >
      <Outlet />
    </Container>
  );
};

export default GuestLayout;
