import {
  bkash,
  bkashAgent,
  cityBank,
  dbbl,
  localAgent,
  nagad,
  nagadAgent,
  rocket,
  rocketAgent,
  upay,
} from 'assets';

const formatError = (error) => {
  console.log('on res', error);
  if (!error.response) {
    return 'Something went wrong';
  }
  if (!error.response.status) {
    return 'Network/Server Error';
  }
  if (error.response.data && Array.isArray(error.response.data.errors)) {
    return error.response.data.errors[0].msg;
  }
};

const getMethodIconByName = (name) => {
  switch (name) {
    case 'bkash': {
      return bkash;
    }
    case 'bkash-agent': {
      return bkashAgent;
    }
    case 'nagad': {
      return nagad;
    }
    case 'nagad-agent': {
      return nagadAgent;
    }
    case 'dbbl': {
      return dbbl;
    }
    case 'city-bank': {
      return cityBank;
    }
    case 'rocket': {
      return rocket;
    }
    case 'rocket-agent': {
      return rocketAgent;
    }
    case 'local-agent': {
      return localAgent;
    }
    case 'upay': {
      return upay;
    }
    default: {
      return localAgent;
    }
  }
};

export { formatError, getMethodIconByName };
