import { LoadingSpinner, UserLayout } from 'components';
import { useAuth } from 'hooks/useAuth';
import { useMethods } from 'hooks/useMethods';
import { usePaymentMethods } from 'hooks/useUserMethods';
import { useSettings } from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProjectUser = () => {
  const {
    isLoading: authLoading,
    getUserProfile,
    isLoggedIn,
    role,
    profile,
  } = useAuth();

  const { isLoading: settingsLoading, getUserSettings } = useSettings();
  const { isLoading: methodsLoading, getAllMethods } = useMethods();
  const [loaded, setLoaded] = useState(false);
  const {
    isLoading: myMethodsLoading,
    getMyMethods,
    getPaymentMethods,
  } = usePaymentMethods();

  console.log({ authLoading });

  console.log({
    authLoading,
    settingsLoading,
    methodsLoading,
    myMethodsLoading,
  });

  useEffect(() => {
    getUserProfile();
    getUserSettings();
    getAllMethods();
    getMyMethods();
    getPaymentMethods();
    setLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn || role !== 'user') {
    return <Navigate to="/" />;
  }
  if (
    !loaded ||
    authLoading ||
    settingsLoading ||
    methodsLoading ||
    myMethodsLoading
  ) {
    return <LoadingSpinner />;
  }

  return (
    <UserLayout>
      <Outlet />
    </UserLayout>
  );
};
export default ProjectUser;
