import { LoadingButton } from '@mui/lab';
import { Alert, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import ReferralBonusForm from 'components/ref-bonus/ReferralBonusForm';
import UpdateUser from 'components/updateUser/UpdateUser';
import { format } from 'date-fns';
import { useAdmin } from 'hooks/useAdmin';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useClipboard } from 'use-clipboard-copy';

const UserDetailPage = () => {
  const { state } = useLocation();
  const { isRemoving, removeUser } = useAdmin();

  const navigate = useNavigate();

  console.log({ state });
  if (!state.user) {
    return (
      <Container>
        <Alert severity="error">No user found</Alert>
      </Container>
    );
  }

  function handleRemove() {
    if (state.user._id) {
      if (window.confirm(`Are you sure to remove ${state.user.name}`)) {
        console.log('done');
        removeUser(state.user._id, () => navigate(-1));
      }
    }
  }

  const {
    name,
    userId,
    createdAt,
    balance,
    deposit,
    withdraw,
    phone,
    email,
    ownCode,
    affTotal,
    referralCode,
    bonusPercent,
    role,
  } = state.user;

  return (
    <Container>
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        {name} ({userId}) Details
      </Typography>
      <TitleValue title="User name" value={name} />
      <TitleValue title="User Id" value={userId} />
      <TitleValue title="Phone no." value={phone} />
      <TitleValue title="Email" value={email} />
      <TitleValue
        title="Registration date"
        value={format(new Date(createdAt), 'dd-MM-yyyy')}
      />
      <TitleValue title="Balance" value={balance} />
      <TitleValue title="User Type" value={role} />
      <TitleValue title="Total Deposit" value={deposit} />
      <TitleValue title="Total Withdraw" value={withdraw} />
      <TitleValue title="Total Bonus" value={affTotal} />
      <TitleValue title="Affiliate Code (Nijer code)" value={ownCode} />
      <TitleValue
        title="Referral Code (Jar maddhobe hoise)"
        value={referralCode}
      />
      <TitleValue title="Bonus percent" value={bonusPercent} />
      <ReferralBonusForm name={name} userId={userId} />
      <UpdateUser user={state.user} />
      <LoadingButton
        loading={false}
        onClick={handleRemove}
        variant="contained"
        color="error"
        fullWidth
        sx={{ marginTop: 2 }}
      >
        Remove User
      </LoadingButton>
    </Container>
  );
};

export default UserDetailPage;

function TitleValue({ title, value }) {
  const clip = useClipboard();

  function onCopy() {
    clip.copy(value);
    window?.ReactNativeWebView?.postMessage(value);
    toast.success(`${title} Copied`);
  }

  return (
    <Stack
      sx={{ cursor: 'pointer' }}
      onClick={onCopy}
      direction="row"
      spacing={1}
      my={2}
      alignItems="center"
    >
      <Typography>{title} - </Typography>
      <Typography fontWeight="600">{value}</Typography>
    </Stack>
  );
}
