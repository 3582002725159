import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useOTP = create((set, get) => ({
  isLoading: false,
  isSent: false,

  sendOTPRequest: async (onSuccess) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('user/send-otp');
      if (response.status === 200) {
        toast.success('OTP Send successfully');
        onSuccess && onSuccess();
        set(() => ({ isLoading: false, isSent: true }));
      }
    } catch (error) {
      toast.error('Failed to send OTP');
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  verifyOTPRequest: async (formValue, onSuccess) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('user/verify-otp', {
        ...formValue,
      });
      if (response.status === 200) {
        toast.success('OTP Verified!');
        set(() => ({ isLoading: false }));
        onSuccess();
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
