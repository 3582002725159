import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from 'utils/config';
import { formatError } from 'utils/helpers';
import { getValue } from 'utils/storage';

const apiClient = axios.create({
  baseURL: config.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    // Pragma: 'no-cache',
    // Expires: 0,
    // cache: 'no-store',
  },
});

apiClient.interceptors.request.use(
  async (requestConfig) => {
    const token = getValue('token');
    console.log('token ', token);
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`;
    }
    console.log(
      'REQ:::',
      requestConfig.baseURL + requestConfig.url,
      requestConfig
    );
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => {
    console.log('SUCCESS RES::: ', response);
    console.log('here on error eq');
    return response;
  },
  async (error) => {
    console.log('here on error', error);
    const errorMessage = formatError(error);
    toast.error(errorMessage);
    if (error?.message === 'Request failed with status code 500') {
      localStorage.clear();
      window.location('/login');
    }
    return Promise.reject(error);
  }
);

export default apiClient;
