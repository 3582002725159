import { Container, List } from '@mui/material';
import { MethodCard } from 'components';
import AddUserPaymentMethod from 'components/payment-methods/AddUserPaymentMethod';
import { usePaymentMethods } from 'hooks/useUserMethods';
import React from 'react';

const UserPaymentMethods = () => {
  const { myMethods, removeMethod } = usePaymentMethods();

  return (
    <Container sx={{ marginTop: 10, borderWidth: 2 }}>
      <AddUserPaymentMethod />
      <List sx={{ padding: '5px 12px' }}>
        {myMethods.map((method) => (
          <MethodCard
            key={method._id}
            method={method}
            onRemove={removeMethod}
          />
        ))}
      </List>
    </Container>
  );
};

export default UserPaymentMethods;
