import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useSettings = create((set, get) => ({
  isLoading: false,
  settings: undefined,
  isUpdating: false,
  userSettings: undefined,

  getAppSettings: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('admin/settings');
      if (response.status === 200) {
        set(() => ({ isLoading: false, settings: response.data?.data }));
      }
    } catch (error) {
      console.log('Error on get settings', error);
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  getUserSettings: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('user/settings');
      if (response.status === 200) {
        set(() => ({ isLoading: false, userSettings: response.data?.data }));
      }
    } catch (error) {
      console.log('Error on get userSettings', error);
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  reloadUserSetting: async () => {
    try {
      const response = await apiClient.get('user/settings');
      if (response.status === 200) {
        set(() => ({ isLoading: false, userSettings: response.data?.data }));
      }
    } catch (error) {
      console.log('Error on get userSettings', error);
    } finally {
    }
  },

  updateSettings: async (data) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.post('admin/settings', { ...data });
      if (response.status === 200) {
        toast.success('Settings updated');
        get().getAllSettings();
      }
    } catch (error) {
      console.log('Error on update settings', error);
    } finally {
      set(() => ({ isUpdating: false }));
    }
  },
}));
