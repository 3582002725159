import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = ({ to, text }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Button fullWidth variant="contained">
        {text}
      </Button>
    </Link>
  );
};

export default LinkButton;
