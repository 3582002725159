import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import { userUserSearch } from 'hooks/useUserSearch';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'utils/appRoutes';
import { useDebounce } from 'hooks/useDebounce';

const SearchUsers = () => {
  const { isLoading: loading, fetchUsers, users } = userUserSearch();

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const navigate = useNavigate();

  useEffect(() => {
    console.log('here', debouncedSearchTerm);
    if (loading || debouncedSearchTerm.length < 1) return;
    return () => {
      fetchUsers(debouncedSearchTerm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, debouncedSearchTerm, loading]);

  const goToUserDetail = (user) => {
    navigate(appRoutes.ADMIN_USER_DETAILS, {
      state: { user },
    });
  };

  return (
    <Stack my={2} px={2}>
      <TextField
        placeholder="Search User"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {loading && (
        <CircularProgress sx={{ marginTop: 2 }} color="inherit" size={20} />
      )}
      {users?.length > 0 && (
        <List>
          {users.map((user) => (
            <ListItem
              key={user._id}
              sx={{ cursor: 'pointer' }}
              onClick={() => goToUserDetail(user)}
            >
              <ListItemText
                primary={`${user.name} - ${user.userId}`}
                secondary={user.phone}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};

export default SearchUsers;
