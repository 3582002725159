import { Stack } from '@mui/material';
import { LinkButton } from 'components';
import LogoWithName from 'components/common/LogoWithName';
import React from 'react';

const WelcomePage = () => {
  return (
    <Stack
      sx={{ padding: '2rem', height: '80vh' }}
      justifyContent="center"
      alignItems="center"
    >
      <LogoWithName />
      <LinkButton to="/login" text="Get Started" />
    </Stack>
  );
};

export default WelcomePage;
