import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useComplain } from 'hooks/useComplain';
import { useSettings } from 'hooks/useSettings';
import { usePaymentMethods } from 'hooks/useUserMethods';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

const ComplainPage = () => {
  const { paymentMethods } = usePaymentMethods();
  const { createComplain, isLoading } = useComplain();
  const { userSettings } = useSettings();

  const [coin, setCoin] = useState('');
  const [taka, setTaka] = useState('');
  const [method, setMethod] = useState('');

  const [message, setMessage] = useState('');

  function handleSelectChange(e) {
    setMethod(e.target.value);
  }

  function handelSubmit() {
    if (!coin || !taka || !method || !message) {
      return toast.error('All fields are required');
    }
    createComplain({
      coin,
      taka,
      method,
      rate: userSettings.coinRate,
      message,
    });
  }

  return (
    <div>
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        Create Complain
      </Typography>
      <Stack p={2} spacing={2}>
        <TextField
          fullWidth
          label="Coin Amount"
          type="number"
          value={coin}
          onChange={(e) => setCoin(e.target.value)}
        />
        <TextField
          fullWidth
          label="Taka Amount"
          type="number"
          value={taka}
          onChange={(e) => setTaka(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="method-select">Payment method</InputLabel>
          <Select
            labelId="method-select"
            id="select"
            value={method}
            label="Payment method"
            onChange={handleSelectChange}
          >
            {paymentMethods?.map((method) => (
              <MenuItem key={method._id} value={method._id}>
                {method.title} ({method.accountNumber})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Your message"
          type="text"
          value={message}
          multiline={true}
          rows={4}
          onChange={(e) => setMessage(e.target.value)}
        />
        <LoadingButton
          loading={isLoading}
          onClick={handelSubmit}
          variant="contained"
        >
          Create Complain
        </LoadingButton>
      </Stack>
    </div>
  );
};

export default ComplainPage;
