import { LoadingButton } from '@mui/lab';
import { Modal, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { userAdminComplains } from 'hooks/userAdminComplain';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

const ResolveComplainForm = ({ id }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');

  const { isUpdating, resolveComplain } = userAdminComplains();

  function onResolve() {
    if (!message) return toast.error('Provide a message');
    resolveComplain({ id, message }, () => setIsVisible(false));
  }

  return (
    <div>
      <LoadingButton
        variant="contained"
        size="small"
        onClick={() => setIsVisible(true)}
      >
        Resolve Complain
      </LoadingButton>

      <Modal open={isVisible} onClose={() => setIsVisible(false)}>
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography color="primary" textAlign={'center'} fontWeight="500">
              Resolve complain
            </Typography>
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message"
              multiline
              rows={3}
            />
            <LoadingButton
              variant="contained"
              size="small"
              loading={isUpdating}
              onClick={() => onResolve()}
            >
              Resolve Complain
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default ResolveComplainForm;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
};
