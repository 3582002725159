const setValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getValue = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const clearValue = (key) => {
  localStorage.removeItem(key);
};

const destroyStorage = () => {
  localStorage.clear();
};

export { setValue, getValue, clearValue, destroyStorage };
