import { LoadingButton } from '@mui/lab';
import { Card, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { LoadingSpinner } from 'components';
import ResolveComplainForm from 'components/complains/ResolveComplainForm';
import { userAdminComplains } from 'hooks/userAdminComplain';
import React, { useEffect } from 'react';

const AdminComplainPage = () => {
  const { complainsData, getPendingComplains, isLoading } =
    userAdminComplains();

  useEffect(() => {
    getPendingComplains();
  }, []);

  return (
    <Container mx={2}>
      {isLoading && <LoadingSpinner />}
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        Pending Complains
      </Typography>
      {complainsData?.complains?.length > 0 &&
        complainsData.complains.map((complain) => (
          <Card key={complain._id} sx={{ marginBottom: 2, padding: 2 }}>
            <Stack spacing={1}>
              <Typography>Taka - {complain.taka}</Typography>
              <Typography>Coin - {complain.coin}</Typography>
              <Typography>
                User - {complain?.user?.name} ({complain?.user?.userId})
              </Typography>
              <Typography>Message - {complain?.message}</Typography>
              <ResolveComplainForm id={complain._id} />
            </Stack>
          </Card>
        ))}
    </Container>
  );
};

export default AdminComplainPage;
