import {
  Alert,
  Button,
  Card,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import React, { useEffect } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAuth } from 'hooks/useAuth';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useClipboard } from 'use-clipboard-copy';
import { toast } from 'react-toastify';
import { useSettings } from 'hooks/useSettings';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'utils/appRoutes';
import Marquee from 'react-fast-marquee';

const InfoCard = ({ title, value, color, hideBDT, other }) => {
  return (
    <IconButton sx={{ width: '100%', display: 'block' }}>
      <Card
        raised
        sx={{ padding: '0.5rem 2rem', borderBottom: `5px solid ${color}` }}
      >
        <Stack alignItems="flex-start" my={2}>
          <Typography color={blueGrey[400]} fontSize={14}>
            {title}
          </Typography>
          {(value || value === 0) && (
            <Typography color={blueGrey[700]} fontWeight={600} variant="h6">
              {!hideBDT && 'BDT'}{' '}
              <span style={{ color }}>{parseFloat(value).toFixed(2)}</span>
            </Typography>
          )}
          <Stack
            alignSelf="end"
            fontSize="32px"
            sx={{ position: 'absolute', right: 16, top: '35%' }}
          >
            <ChevronRightIcon color="gray" fontSize="inherit" />
          </Stack>
          {other}
        </Stack>
      </Card>
    </IconButton>
  );
};

const UserHomePage = () => {
  const { profile } = useAuth();
  const clipboard = useClipboard();
  const { userSettings, reloadUserSetting } = useSettings();
  const navigate = useNavigate();

  const isAff = profile?.ownCode !== '';

  function onCopy() {
    clipboard.copy(profile?.ownCode);
    window?.ReactNativeWebView?.postMessage(profile?.ownCode);
    toast.success('Affiliate Code Copied');
  }

  const totalCoin = parseFloat(
    profile.balance / userSettings?.coinRate
  ).toFixed(2);

  const goToWithdraw = () => navigate(appRoutes.AFFILIATE_WITHDRAW);

  useEffect(() => {
    reloadUserSetting();
  }, []);

  return (
    <Stack sx={{ margin: 2, paddingBottom: 20 }} spacing={2}>
      {isAff && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Typography fontSize={15}>
            Your affiliate code - <b>{profile?.ownCode} </b>
          </Typography>
          <IconButton onClick={onCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Stack>
      )}
      {userSettings?.noticeText && (
        <Marquee gradient={false} speed={60}>
          <Typography color="green">{userSettings.noticeText}</Typography>
        </Marquee>
      )}

      {isAff ? (
        <>
          <InfoCard
            title="Total Commission"
            value={profile.affTotal}
            color="#66C293"
          />
          <InfoCard
            title="Paid Commission"
            value={profile.affPaid}
            color="#F37A6F"
          />
          <InfoCard
            title="Payable Commission"
            value={parseFloat(profile.affTotal - profile.affPaid)}
            color="#66C293"
          />
          <InfoCard
            title="Payment Request"
            color="#F37A6F"
            other={
              <Button
                onClick={goToWithdraw}
                variant="contained"
                sx={{ marginTop: 2 }}
                size="small"
              >
                Payment Request
              </Button>
            }
          />
        </>
      ) : (
        <>
          <InfoCard
            title="Total Balance"
            value={profile.balance}
            color="#5464AF"
          />
          <InfoCard
            title="Total Coin"
            value={totalCoin}
            color="#F37A6F"
            hideBDT
          />
          <InfoCard
            title="Total Deposit"
            value={profile.deposit}
            color="#66C293"
          />
          <InfoCard
            title="Total Withdraw"
            value={profile.withdraw}
            color="#F37A6F"
          />
        </>
      )}
    </Stack>
  );
};

export default UserHomePage;
