import { FamilyRestroomTwoTone } from '@mui/icons-material';
import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import appRoutes from 'utils/appRoutes';
import create from 'zustand';

export const useAdmin = create((set, get) => ({
  isLoading: false,
  userData: {},
  settings: {},
  bonusLoading: false,
  isRemoving: false,

  addNewUser: async (formValue, navigator) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('admin/add-user', { ...formValue });
      if (response.status === 200) {
        toast.success('User added successfully');
        get().getAllUsers();
        set(() => ({ isLoading: false }));
        navigator(appRoutes.ADMIN_USER_LIST);
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  giveBonusToUser: async (formValue, onEnd) => {
    set(() => ({ bonusLoading: true }));
    try {
      const response = await apiClient.post('admin/give-bonus', {
        ...formValue,
      });
      if (response.status === 201) {
        toast.success('Bonus given to user');
        get().getAllUsers();
        set(() => ({ bonusLoading: false }));
        onEnd();
      }
    } catch (error) {
    } finally {
      set(() => ({ bonusLoading: false }));
    }
  },
  getAllUsers: async (page) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(`admin/users?page=${page}&limit=10`);
      if (response.status === 200) {
        const userData = response.data.data;
        set(() => ({ userData, isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  updateUserData: async (data, onDone) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.put(`admin/update-user-data`, {
        ...data,
      });
      if (response.status === 200) {
        toast.success('User updated');
        onDone();
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  removeUser: async (userId, onDone) => {
    set(() => ({ isRemoving: true }));
    try {
      const response = await apiClient.delete(`user?userId=${userId}`);
      if (response.status === 200) {
        toast.success('User Removed');
        onDone();
      }
    } catch (error) {
    } finally {
      set(() => ({ isRemoving: false }));
    }
  },
}));
