import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { USER_METHODS } from 'utils/constants';
import { toast } from 'react-toastify';
import { usePaymentMethods } from 'hooks/useUserMethods';
import { LoadingButton } from '@mui/lab';
import OTPVerify from 'components/otp-verify/OTPVerify';

const AddUserPaymentMethod = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [accountType, setAccountType] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [otp, setOtp] = useState('');
  const { addMethod, isAdding } = usePaymentMethods();

  const handleChange = (event) => {
    setAccountType(event.target.value);
  };

  function handleAdd() {
    if (!accountType || !accountNo || otp.length !== 6) {
      return toast.error('Fill all the fields');
    }
    addMethod({
      name: accountType,
      title: USER_METHODS.find((acc) => acc.name === accountType)?.title,
      otp,
      accountNumber: accountNo,
    });
  }
  return (
    <div>
      <Stack mb={1} mt={2} mx={5}>
        <Button onClick={() => setIsVisible(true)} variant="contained">
          Add new Method
        </Button>
      </Stack>
      <Modal open={isVisible} onClose={() => setIsVisible(false)}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add payment method
          </Typography>
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="type">Account type</InputLabel>
            <Select
              labelId="type"
              value={accountType}
              label="Account type"
              onChange={handleChange}
            >
              {USER_METHODS.map((user) => (
                <MenuItem key={user.name} value={user.name}>
                  {user.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Account no."
            value={accountNo}
            onChange={(e) => setAccountNo(e.target.value)}
          />
          <OTPVerify otp={otp} setOtp={setOtp} />
          <LoadingButton
            loading={isAdding}
            onClick={handleAdd}
            variant="contained"
            fullWidth
            sx={{ my: 2 }}
          >
            Add
          </LoadingButton>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUserPaymentMethod;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
