const appRoutes = {
  // guest
  WELCOME: '/',
  LOGIN: '/login',
  VERIFY_OTP: '/verify',

  // Admin
  ADMIN_DASHBOARD: '/admin',
  ADMIN_ADD_USER: '/admin/add-user',
  ADMIN_USER_LIST: '/admin/user-list',
  ADMIN_TODAYS_TRANSACTIONS: '/admin/today-transactions',
  ADMIN_USER_DETAILS: '/admin/user-detail',
  ADMIN_GIVE_AFF: '/admin/give-aff',
  ADMIN_PENDING_DEPOSITS: '/admin/pending-deposits',
  ADMIN_PENDING_WITHDRAWS: '/admin/pending-withdrawal',
  ADMIN_APP_SETTINGS: '/admin/app_settings',
  ADMIN_PAYMENT_METHODS: '/admin/methods',
  ADMIN_ADD_PAYMENT_METHODS: '/admin/payment-methods',
  ADMIN_COMPLAINS: '/admin/complains',
  ADMIN_UPDATE_PROFILE: '/admin/update-profile',

  // Stuff
  STUFF_DASHBOARD: '/stuff',
  STUFF_TODAYS_TRANSACTIONS: '/stuff/today-transactions',
  STUFF_PENDING_DEPOSITS: '/stuff/pending-deposits',
  STUFF_PENDING_WITHDRAWS: '/stuff/pending-withdrawal',
  STUFF_COMPLAINS: '/stuff/complains',
  STUFF_UPDATE_PROFILE: '/stuff/update-profile',

  // user
  USER_HOME: '/user',
  DEPOSIT_NOW: '/user-deposit',
  WITHDRAW_NOW: '/user/withdraw-now',
  AFFILIATE_WITHDRAW: '/user/aff-withdraw',
  DEPOSIT_PAYMENT: '/user/deposit-payment',
  HISTORY: '/user/history',
  COMPLAIN: '/user/complain',
  CONTACT_SUPPORT: '/user/contact-support',
  PAYMENT_METHODS: '/user/payment-methods',
  USER_PAYMENT_METHODS: '/user/payment-methods',
  USER_COMPLAIN_HISTORY: '/user/complain_history',
  USER_PROFILE_SETTINGS: '/user/profile_settings',
};

export default appRoutes;
