let baseUrl = 'http://localhost:8282/api';

if (process.env.NODE_ENV === 'production') {
  baseUrl = '/api';
} else {
  baseUrl = 'http://localhost:8282/api';
}

export const config = {
  baseUrl,
};
