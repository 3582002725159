import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Typography } from '@mui/material';
import { useOTP } from 'hooks/useOTP';
import React, { useEffect, useState } from 'react';

const OTPVerify = ({ otp, setOtp }) => {
  const { isLoading, sendOTPRequest } = useOTP();
  const [isSent, setIsSent] = useState('');
  const [seconds, setSeconds] = useState(50);

  function onSendOTP() {
    sendOTPRequest(() => setIsSent(true));
  }

  useEffect(() => {
    if (!isSent) return;
    let myInterval = window.setInterval(() => {
      if (seconds > 0) {
        setSeconds((ps) => ps - 1);
      }
      if (seconds === 0) {
        setSeconds(0);
        setIsSent(false);
        return window.clearInterval(myInterval);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, isSent]);

  return (
    <Stack py={2} alignItems="center" direction="row">
      <TextField
        sx={{ flex: 1 }}
        label="6 digit OTP"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />
      <Stack px={2}>
        {isSent ? (
          <Typography fontSize={14}>
            OTP Expires in <b>{seconds}</b> seconds
          </Typography>
        ) : (
          <LoadingButton loading={isLoading} onClick={onSendOTP}>
            Send OTP
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

export default OTPVerify;
