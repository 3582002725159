import React, { useState } from 'react';
import appRoutes from 'utils/appRoutes';
import { DrawerMenu, TopBar } from 'components';
import PaidIcon from '@mui/icons-material/Paid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import HistoryIcon from '@mui/icons-material/History';
import CommentIcon from '@mui/icons-material/Comment';
import SettingsIcon from '@mui/icons-material/Settings';
import GridViewIcon from '@mui/icons-material/GridView';
import { useAuth } from 'hooks/useAuth';
import HelpLine from './HelpLine';

const UserLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { profile } = useAuth();
  const isAff = profile?.ownCode !== '';

  const menuData = [
    {
      id: '8',
      title: 'Dashboard',
      route: appRoutes.USER_HOME,
      icon: <GridViewIcon />,
    },
    {
      id: '6',
      title: 'Payment methods',
      route: appRoutes.USER_PAYMENT_METHODS,
      icon: <PaidIcon />,
    },
    {
      id: '1',
      title: 'Deposit now',
      route: appRoutes.DEPOSIT_NOW,
      icon: <AddCircleOutlineIcon />,
      hidden: !!isAff,
    },
    {
      id: '2',
      title: 'Withdraw now',
      route: appRoutes.WITHDRAW_NOW,
      icon: <CallMissedOutgoingIcon />,
      hidden: !!isAff,
    },
    {
      id: '3',
      title: 'History',
      route: appRoutes.HISTORY,
      icon: <HistoryIcon />,
    },
    {
      id: '4',
      title: 'Complain',
      route: appRoutes.COMPLAIN,
      icon: <CommentIcon />,
    },
    {
      id: '7',
      title: 'Profile settings',
      route: appRoutes.USER_PROFILE_SETTINGS,
      icon: <SettingsIcon />,
    },
  ];

  return (
    <>
      <DrawerMenu
        menuItems={menuData}
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
      />
      <TopBar onOpen={() => setShowMenu(true)} />
      <HelpLine />
      {children}
    </>
  );
};

export default UserLayout;
