import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingSpinner } from 'components';
import { useAdmin } from 'hooks/useAdmin';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box } from '@mui/system';
import Pagination from 'components/common/Pagination';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'utils/appRoutes';
import SearchUsers from 'components/search-users/SearchUsers';

const UsersListPage = () => {
  const navigate = useNavigate();
  const { getAllUsers, userData, isLoading } = useAdmin();
  const [page, setPage] = useState(1);

  useEffect(() => {
    getAllUsers(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const totalPage = get(userData, 'totalPages', '...');
  const onPrev = () => {
    setPage((prev) => prev - 1);
  };
  const onNext = () => {
    setPage((prev) => prev + 1);
  };

  const goToAddUser = () => {
    navigate(appRoutes.ADMIN_ADD_USER);
  };

  const goToUserDetail = (user) => {
    navigate(appRoutes.ADMIN_USER_DETAILS, {
      state: { user },
    });
  };

  return (
    <div>
      <Stack justifyContent="center" mt={2}>
        <Button onClick={goToAddUser} sx={{ margin: '0 auto' }}>
          Add new User
        </Button>
      </Stack>
      <SearchUsers />
      {isLoading && <LoadingSpinner />}
      {userData?.users?.length > 0 && (
        <List sx={{ paddingBottom: 20 }}>
          {userData?.users.map((user) => (
            <ListItem
              key={user._id}
              sx={{ cursor: 'pointer' }}
              onClick={() => goToUserDetail(user)}
              secondaryAction={
                <Box
                  sx={{ display: 'flex', alignItems: 'center', spacing: 1 }}
                  flexDirection="row"
                  alignItems="center"
                  spacing={2}
                >
                  <ArrowUpwardIcon color="success" />
                  <Typography mr={1} color="grayText" fontWeight="500">
                    {user.deposit}
                  </Typography>
                  <ArrowDownwardIcon color="error" />
                  <Typography color="grayText" fontWeight="500">
                    {user.withdraw}
                  </Typography>
                </Box>
              }
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                  {user?.name?.[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user?.name + `${user.ownCode && '    (Affiliate)'}`}
                secondary={user?.userId}
              />
            </ListItem>
          ))}
        </List>
      )}
      <Pagination
        isLoading={isLoading}
        page={page}
        totalPage={totalPage}
        onPrev={onPrev}
        onNext={onNext}
      />
    </div>
  );
};

export default UsersListPage;
