import { Translate } from '@mui/icons-material';
import { CircularProgress, Container, Stack, Typography } from '@mui/material';
import React from 'react';

export const LoadingSpinner = ({ text = 'Loading...' }) => {
  return (
    <Container
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        paddingBottom: 20,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(33,150,243,0.1)',
        zIndex: 1000,
      }}
    >
      <Stack alignItems="center">
        <Stack my={2}>
          <img src="/images/logo.png" alt="logo" height={100} width={100} />
        </Stack>
        <CircularProgress />
        <Typography color="GrayText">{text}</Typography>
      </Stack>
    </Container>
  );
};
