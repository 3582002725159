import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useMethods = create((set, get) => ({
  isLoading: false,
  methods: [],
  isUpdating: false,

  getAllMethods: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('payment-method');
      if (response.status === 200) {
        set(() => ({ isLoading: false, methods: response.data?.data }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  addANumber: async (data, onSuccess) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.post('payment-method', { ...data });
      if (response.status === 201) {
        get().getAllMethods();
        toast.success('Method. added successfully');
        onSuccess();
      }
    } catch (error) {
    } finally {
      set(() => ({ isUpdating: false }));
    }
  },

  removeNumber: async (methodId) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.delete(`payment-method/${methodId}`);
      if (response.status === 200) {
        get().getAllMethods();
        toast.success('Account no. deleted successfully');
      }
    } catch (error) {
    } finally {
      set(() => ({ isUpdating: false }));
    }
  },
}));
