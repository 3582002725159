import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const usePaymentMethods = create((set, get) => ({
  isLoading: false,
  paymentMethods: [],
  myMethods: [],
  isAdding: false,
  isUpdating: false,

  getMyMethods: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('payment-method/my');
      if (response.status === 200) {
        set(() => ({ isLoading: false, myMethods: response.data?.data }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  removeMethod: async (methodId) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.delete(`payment-method/my/${methodId}`);
      if (response.status === 200) {
        get().getMyMethods();
        toast.success('Method deleted successfully');
      }
    } catch (error) {
    } finally {
      set(() => ({ isUpdating: false }));
    }
  },

  addMethod: async (data) => {
    set(() => ({ isAdding: true }));
    try {
      const response = await apiClient.post('payment-method/my', { ...data });
      if (response.status === 201) {
        get().getMyMethods();
        toast.success('Account no. added successfully');
      }
    } catch (error) {
    } finally {
      set(() => ({ isAdding: false }));
    }
  },
  getPaymentMethods: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('payment-method/for-paying');
      if (response.status === 200) {
        set(() => ({ isLoading: false, paymentMethods: response.data?.data }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
