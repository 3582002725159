import { Card, IconButton, Stack, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import React, { useEffect } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAuth } from 'hooks/useAuth';
import { useSettings } from 'hooks/useSettings';
import { useAdminDashboard } from 'hooks/useAdminDashboard';
import { LoadingSpinner } from 'components';

const InfoCard = ({ title, value, color }) => {
  return (
    <IconButton sx={{ width: '100%', display: 'block' }}>
      <Card raised sx={{ padding: '2rem', borderBottom: `5px solid ${color}` }}>
        <Stack alignItems="flex-start" mt={2}>
          <Typography color={blueGrey[400]}>{title}</Typography>
          <Typography color={blueGrey[700]} fontWeight={600} variant="h5">
            <span style={{ color }}>{value}</span>
          </Typography>
          <Stack alignSelf="end" fontSize="40px">
            <ChevronRightIcon color="gray" fontSize="inherit" />
          </Stack>
        </Stack>
      </Card>
    </IconButton>
  );
};

const AdminHomePage = () => {
  const { settings } = useSettings();

  const { dashboardData, isLoading, getDashboardData } = useAdminDashboard();

  useEffect(() => {
    getDashboardData();
    return () => {};
  }, []);

  console.log({ dashboardData });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Stack sx={{ margin: 2 }} spacing={2}>
      <InfoCard
        title="Coin Rate"
        value={`1 coin = BDT${settings?.coinRate}`}
        color="#66C293"
      />
      <InfoCard
        title="Total Users"
        value={dashboardData.totalUsers}
        color="#5464AF"
      />
      <InfoCard
        title="Total Transactions"
        value={dashboardData.totalTransactions}
        color="#5464AF"
      />
    </Stack>
  );
};

export default AdminHomePage;
