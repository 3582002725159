import React, { useState } from 'react';
import appRoutes from 'utils/appRoutes';
import { DrawerMenu, TopBar } from 'components';
import GridViewIcon from '@mui/icons-material/GridView';
import Person from '@mui/icons-material/Person';
import LocalAtm from '@mui/icons-material/LocalAtm';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Settings from '@mui/icons-material/Settings';
import AppSettingsAlt from '@mui/icons-material/AppSettingsAlt';
import PendingIcon from '@mui/icons-material/Pending';
import AddBox from '@mui/icons-material/AddBox';
import TodayIcon from '@mui/icons-material/Today';
import HelpLine from './HelpLine';

const menuData = [
  {
    id: '8',
    title: 'Dashboard',
    route: appRoutes.ADMIN_DASHBOARD,
    icon: <GridViewIcon />,
  },
  {
    id: '6',
    title: 'All users list',
    route: appRoutes.ADMIN_USER_LIST,
    icon: <Person />,
  },
  {
    id: '6334',
    title: `Today's Transactions`,
    route: appRoutes.ADMIN_TODAYS_TRANSACTIONS,
    icon: <TodayIcon />,
  },
  {
    id: '3',
    title: 'Payment Methods',
    route: appRoutes.ADMIN_PAYMENT_METHODS,
    icon: <AttachMoney />,
  },
  {
    id: '1',
    title: 'Pending Deposits',
    route: appRoutes.ADMIN_PENDING_DEPOSITS,
    icon: <AddBox />,
  },
  {
    id: '2',
    title: 'Pending Withdraw',
    route: appRoutes.ADMIN_PENDING_WITHDRAWS,
    icon: <LocalAtm />,
  },

  {
    id: '5',
    title: 'Complains',
    route: appRoutes.ADMIN_COMPLAINS,
    icon: <PendingIcon />,
  },
  {
    id: '4',
    title: 'App Settings',
    route: appRoutes.ADMIN_APP_SETTINGS,
    icon: <AppSettingsAlt />,
  },
  {
    id: '7',
    title: 'Profile settings',
    route: appRoutes.ADMIN_UPDATE_PROFILE,
    icon: <Settings />,
  },
];

const AdminLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <DrawerMenu
        menuItems={menuData}
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
      />
      <TopBar onOpen={() => setShowMenu(true)} />
      <HelpLine />
      {children}
    </>
  );
};

export default AdminLayout;
