import { LoadingButton } from '@mui/lab';
import {
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import { useSettings } from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const AppSettingsPage = () => {
  const { settings, updateSettings, isUpdating } = useSettings();

  console.log({ settings });

  const [coinRate, setCoinRate] = useState('0');
  const [bonusPercent, setBonusPercent] = useState('0');
  const [bonusText, setBonusText] = useState('');
  const [noticeText, setNoticeText] = useState('');
  const [withdrawLimit, setWithdrawLimit] = useState('');
  const [allowRefWithdraw, setAllowRefWithdraw] = useState(false);
  const [otpEnabled, setOtpEnabled] = useState(false);

  const handleSave = () => {
    if (!coinRate) {
      return toast.error('Coin rate is required');
    }
    updateSettings({
      coinRate,
      bonusPercent,
      bonusText,
      noticeText,
      withdrawLimit,
      allowRefWithdraw,
      otpEnabled,
    });
  };

  useEffect(() => {
    if (settings?.coinRate) {
      setCoinRate(settings?.coinRate.toString());
    }
    if (settings?.bonusPercent) {
      setBonusPercent(settings?.bonusPercent.toString());
    }
    if (settings?.bonusText) {
      setBonusText(settings?.bonusText.toString());
    }
    if (settings?.noticeText) {
      setNoticeText(settings?.noticeText.toString());
    }
    if (settings?.withdrawLimit) {
      setWithdrawLimit(settings?.withdrawLimit.toString());
    }
    if (settings?.allowRefWithdraw) {
      setAllowRefWithdraw(settings?.allowRefWithdraw.toString());
    }
    if (settings?.otpEnabled) {
      setOtpEnabled(settings?.otpEnabled);
    }
  }, [settings]);
  return (
    <Container sx={{ padding: 2 }}>
      <Typography color="primary" textAlign={'center'} fontWeight="500" pb={2}>
        App settings for users
      </Typography>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="Coin rate: 1 coin = ? taka"
          value={coinRate}
          onChange={(e) => setCoinRate(e.target.value)}
          type="number"
        />
        <TextField
          fullWidth
          label="Withdraw Limit (at a time)"
          value={withdrawLimit}
          onChange={(e) => setWithdrawLimit(e.target.value)}
          type="number"
        />
        <Divider />
        <Divider />
        <TextField
          fullWidth
          label="Bonus percent"
          value={bonusPercent}
          onChange={(e) => setBonusPercent(e.target.value)}
          type="number"
        />

        <TextField
          fullWidth
          label="Bonus text (for users)"
          value={bonusText}
          onChange={(e) => setBonusText(e.target.value)}
          type="text"
        />
        <TextField
          fullWidth
          label="Notice text"
          value={noticeText}
          onChange={(e) => setNoticeText(e.target.value)}
          type="text"
          multiline
          rows={2}
        />
        <FormControlLabel
          control={
            <Switch
              checked={allowRefWithdraw}
              onChange={() => setAllowRefWithdraw((p) => !p)}
            />
          }
          label="Allow referral withdraw"
        />
        <FormControlLabel
          control={
            <Switch
              checked={otpEnabled}
              onChange={() => setOtpEnabled((p) => !p)}
            />
          }
          label="Enable Login OTP"
        />

        <LoadingButton
          loading={isUpdating}
          variant="contained"
          onClick={handleSave}
        >
          Save settings
        </LoadingButton>
      </Stack>
    </Container>
  );
};

export default AppSettingsPage;
