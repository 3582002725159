import apiClient from 'api/apiClient';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import appRoutes from 'utils/appRoutes';
import { clearValue, destroyStorage, getValue, setValue } from 'utils/storage';
import create from 'zustand';

export const useAuth = create((set) => ({
  isLoading: false,
  isLoggedIn: false,
  profile: undefined,
  role: '',

  loginUser: async (formValue, navigate) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('user/login', { ...formValue });
      const token = get(response, 'data.data.token', '');
      if (token) {
        // do login
        const role = get(response, 'data.data.role', '');
        const profile = get(response, 'data.data.profile', {});
        setValue('token', token);
        setValue('role', role);
        setValue('profile', profile);
        set(() => ({ isLoading: false, isLoggedIn: true, role, profile }));
        return;
      }

      // do otp stuff
      const phone = get(response, 'data.data.phone', '');
      const userId = get(response, 'data.data.userId', '');

      navigate(appRoutes.VERIFY_OTP, { state: { phone, userId } });
      set(() => ({ isLoading: false }));
    } catch (error) {
      set(() => ({ isLoading: false }));
    }
  },

  setLoggedIn: async () => {
    const role = getValue('role');
    set(() => ({ isLoggedIn: true, role }));
    /* Profile should be fetched here */
  },

  logoutUser: async () => {
    clearValue('token');
    set(() => ({ isLoggedIn: false, role: '', profile: undefined }));
  },

  verifyOTP: async (formValue) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('user/verify', { ...formValue });
      const token = get(response, 'data.data.token', '');
      const role = get(response, 'data.data.role', '');
      const profile = get(response, 'data.data.profile', {});
      setValue('token', token);
      setValue('role', role);
      setValue('profile', profile);
      set(() => ({ isLoading: false, isLoggedIn: true, role, profile }));
    } catch (error) {
      set(() => ({ isLoading: false }));
    }
  },

  getUserProfile: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('user/profile');
      set(() => ({ isLoading: false, profile: response.data.data }));
    } catch (error) {
      set(() => ({ isLoading: false }));
      destroyStorage();
      window.location.replace('/login');
    }
  },
}));
