import { List } from '@mui/material';
import { LoadingSpinner, MethodCard } from 'components';
import AddAdminPaymentMethod from 'components/payment-methods/AddAdminPaymentMethod';
import { useMethods } from 'hooks/useMethods';
import React, { useEffect } from 'react';

const AdminMethodsPage = () => {
  const { getAllMethods, methods, isLoading, removeNumber, isUpdating } =
    useMethods();

  useEffect(() => {
    getAllMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRemove(methodId) {
    removeNumber(methodId);
  }

  return (
    <div>
      <AddAdminPaymentMethod />
      {(isUpdating || isLoading) && <LoadingSpinner />}
      <List sx={{ padding: '5px 12px' }}>
        {methods &&
          methods.length > 0 &&
          methods.map((method) => (
            <MethodCard
              key={method._id}
              method={method}
              onRemove={handleRemove}
            />
          ))}
      </List>
    </div>
  );
};

export default AdminMethodsPage;
