import React, { useState } from 'react';
import appRoutes from 'utils/appRoutes';
import { DrawerMenu, TopBar } from 'components';
import GridViewIcon from '@mui/icons-material/GridView';
import LocalAtm from '@mui/icons-material/LocalAtm';
import Settings from '@mui/icons-material/Settings';
import PendingIcon from '@mui/icons-material/Pending';
import AddBox from '@mui/icons-material/AddBox';
import TodayIcon from '@mui/icons-material/Today';
import HelpLine from './HelpLine';

const menuData = [
  {
    id: '8',
    title: 'Dashboard',
    route: appRoutes.STUFF_DASHBOARD,
    icon: <GridViewIcon />,
  },
  {
    id: '6334',
    title: `Today's Transactions`,
    route: appRoutes.STUFF_TODAYS_TRANSACTIONS,
    icon: <TodayIcon />,
  },
  {
    id: '1',
    title: 'Pending Deposits',
    route: appRoutes.STUFF_PENDING_DEPOSITS,
    icon: <AddBox />,
  },
  {
    id: '2',
    title: 'Pending Withdraw',
    route: appRoutes.STUFF_PENDING_WITHDRAWS,
    icon: <LocalAtm />,
  },

  {
    id: '5',
    title: 'Complains',
    route: appRoutes.STUFF_COMPLAINS,
    icon: <PendingIcon />,
  },
  {
    id: '7',
    title: 'Profile settings',
    route: appRoutes.STUFF_UPDATE_PROFILE,
    icon: <Settings />,
  },
];

const StuffLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <DrawerMenu
        menuItems={menuData}
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
      />
      <TopBar onOpen={() => setShowMenu(true)} />
      <HelpLine />
      {children}
    </>
  );
};

export default StuffLayout;
