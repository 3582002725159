import { LoadingButton } from '@mui/lab';
import { Modal, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAdmin } from 'hooks/useAdmin';
import { useSettings } from 'hooks/useSettings';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import appRoutes from 'utils/appRoutes';

const ReferralBonusForm = ({ name, userId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [amount, setAmount] = useState('');
  const [coinAmount, setCoinAmount] = useState('');
  const { giveBonusToUser, bonusLoading } = useAdmin();
  const navigate = useNavigate();
  const { userSettings } = useSettings();

  function handleSubmit() {
    if (!amount || typeof amount !== 'number') {
      return toast.error('Invalid amount');
    }
    if (window.confirm(`Are you sure to give ${amount} taka bonus?`)) {
      giveBonusToUser(
        { userId, amount, coin: coinAmount, rate: userSettings.coinRate },
        () => {
          setIsVisible(false);
          navigate(appRoutes.ADMIN_USER_LIST);
        }
      );
    }
  }

  const onChangeTaka = (e) => {
    setCoinAmount('');
    setAmount('');
    const amountInt = parseFloat(e.target.value);
    if (amountInt > 0) {
      const rate = amountInt / parseFloat(userSettings.coinRate);
      setCoinAmount(rate.toFixed(2).toString());
    } else {
      setCoinAmount('');
    }
    setAmount(e.target.valueAsNumber);
  };

  return (
    <Stack>
      <LoadingButton
        loading={bonusLoading}
        variant="contained"
        onClick={() => setIsVisible(true)}
      >
        Give bonus
      </LoadingButton>
      <Modal open={isVisible} onClose={() => setIsVisible(false)}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" mb={2}>
            Give Bonus to user {name} - {userId}
          </Typography>
          <TextField
            fullWidth
            label="Amount in taka"
            value={amount}
            onChange={onChangeTaka}
            type="number"
          />
          <Typography gutterBottom>
            Coin -{' '}
            <Typography variant="span" fontWeight="bold" color="green">
              {coinAmount || 0}
            </Typography>
          </Typography>
          <LoadingButton
            loading={bonusLoading}
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            sx={{ my: 2 }}
          >
            Give Bonus {amount}
          </LoadingButton>
        </Box>
      </Modal>
    </Stack>
  );
};

export default ReferralBonusForm;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
};
