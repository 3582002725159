import React from 'react';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { get } from 'lodash';
import { getMethodIconByName } from 'utils/helpers';

const MethodCard = ({ method, onRemove }) => {
  const methodId = get(method, '_id', '');
  const accountNumber = get(method, 'accountNumber', '');
  const title = get(method, 'title', '');
  const name = get(method, 'name', '');

  const image = getMethodIconByName(name);

  function handleRemove() {
    if (
      window.confirm(
        `Are you sure remove this method ${title} - ${accountNumber}`
      )
    ) {
      onRemove && onRemove(methodId);
    }
  }

  return (
    <ListItem
      secondaryAction={
        <IconButton onClick={handleRemove} edge="end" aria-label="delete">
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar src={image} alt={name} sx={{ width: 40, height: 40 }} />
      </ListItemAvatar>
      <ListItemText primary={title} secondary={accountNumber} />
    </ListItem>
  );
};

export default MethodCard;
