import { Alert, Button, Typography } from '@mui/material';
import React from 'react';
import Marquee from 'react-fast-marquee';

const HelpLine = () => {
  const handle = () => {
    window.location.replace(
      'https://wa.me/8801874256837?text=Hello%20from%20DepositBD%2C%20how%20can%20I%20help%20you%3F%20'
    );
  };

  return (
    <Marquee gradient={false} speed={60}>
      <Button variant="text" color="error" s onClick={handle}>
        <Typography color="red">
           যেকোন সমস্যায় যোগাযোগ করুন 01874256837 (WhatsApp)
        </Typography>
      </Button>
    </Marquee>
  );
};

export default HelpLine;
