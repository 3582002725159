import React from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { UserAvatar } from 'components';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const DrawerMenu = ({ menuItems, isOpen, onClose }) => {
  const navigate = useNavigate();

  const { logoutUser } = useAuth();

  function handleMenuItemClick(route) {
    navigate(route);
    onClose();
  }

  function handleLogout() {
    logoutUser();
    navigate('/login');
  }

  return (
    <Drawer anchor={'left'} open={isOpen}>
      <List
        sx={{
          padding: 2,
          position: 'relative',
          height: '100%',
        }}
      >
        <Stack>
          <IconButton sx={{ marginLeft: 'auto' }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <UserAvatar />
        {menuItems.map(({ id, title, route, icon, hidden }) => {
          if (hidden) return null;
          return (
            <ListItem
              key={id}
              disablePadding
              onClick={() => handleMenuItemClick(route)}
            >
              <ListItemButton>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText color="gray" primary={title} />
              </ListItemButton>
            </ListItem>
          );
        })}
        <Stack sx={{ position: 'absolute', bottom: 10 }}>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText color="gray" primary="Logout" />
          </ListItemButton>
        </Stack>
      </List>
    </Drawer>
  );
};

export default DrawerMenu;
