import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSettings } from 'hooks/useSettings';
import { useTransfer } from 'hooks/useTransfer';
import { usePaymentMethods } from 'hooks/useUserMethods';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useClipboard } from 'use-clipboard-copy';

const DepositForm = ({ isVisible, onClose, methodName }) => {
  const clipboard = useClipboard();
  const navigate = useNavigate();

  const { userSettings } = useSettings();
  const { paymentMethods } = usePaymentMethods();
  const { createTransaction, isLoading: transactionLoading } = useTransfer();

  const allMethods = paymentMethods.filter(
    (method) => method.name === methodName
  );

  const [coinAmount, setCoinAmount] = useState('');
  const [takaAmount, setTakaAmount] = useState('');
  const [trnxId, setTrnxId] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');

  const onChangeTaka = (e) => {
    setCoinAmount('');
    setTakaAmount('');
    const amountInt = parseFloat(e.target.value);
    if (amountInt > 0) {
      const rate = amountInt / parseFloat(userSettings?.coinRate);
      setCoinAmount(rate.toFixed(2).toString());
    } else {
      setCoinAmount('');
    }
    setTakaAmount(e.target.value);
  };

  function onCopyAcc(method) {
    clipboard.copy(method.accountNumber);
    window?.ReactNativeWebView?.postMessage(method.accountNumber);
    toast.success('Copied');
  }

  function onSubmit() {
    if (parseInt(takaAmount) < 300) {
      return toast.error(`The minimum amount should be 300!`);
    }

    if (!selectedMethod || !coinAmount || !trnxId) {
      return toast.error('Fill-up all fields');
    }
    const formValues = {
      method: selectedMethod,
      coin: coinAmount,
      amount: takaAmount,
      rate: userSettings.coinRate,
      transId: trnxId,
    };
    createTransaction(formValues, navigate);
  }

  const handleSelect = (event) => {
    setSelectedMethod(event.target.value);
  };

  return (
    <div>
      <Modal open={isVisible} onClose={onClose}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            textAlign="center"
            color="teal"
          >
            Pay using {methodName}
          </Typography>

          {userSettings?.bonusText && (
            <Alert sx={{ marginTop: 2 }} severity="info">
              {userSettings?.bonusText}
            </Alert>
          )}
          <Typography mt={2} mb={0.2}>
            Account numbers -
          </Typography>
          <Box
            sx={{ border: '1px solid rgba(90, 37, 247, 0.2)', borderRadius: 1 }}
            mb={2}
            px={1}
          >
            <FormControl>
              <FormLabel id="accNo.">Select Account no.</FormLabel>
              <RadioGroup
                aria-labelledby="accNo."
                name="select-acc-no"
                value={selectedMethod}
                onChange={handleSelect}
              >
                {allMethods.map((method) => (
                  <Stack
                    key={method._id}
                    direction="row"
                    alignItems={'center'}
                    spacing={1}
                    py={0.25}
                  >
                    <FormControlLabel
                      value={method._id}
                      control={<Radio />}
                      label={method.accountNumber}
                    />
                    <Button onClick={() => onCopyAcc(method)}>Copy</Button>
                  </Stack>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Transaction Id"
              value={trnxId}
              onChange={(e) => setTrnxId(e.target.value)}
            />
            <TextField
              fullWidth
              label="Amount"
              type="number"
              value={takaAmount}
              onChange={onChangeTaka}
            />
            <Typography gutterBottom>
              Coin -{' '}
              <Typography variant="span" fontWeight="bold" color="green">
                {coinAmount || 0}
              </Typography>
            </Typography>
            <LoadingButton
              onClick={onSubmit}
              variant="contained"
              loading={transactionLoading}
            >
              Request for Deposit
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default DepositForm;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
