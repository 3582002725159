import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const userAdminComplains = create((set, get) => ({
  isLoading: false,
  complainsData: [],
  isUpdating: false,

  resolveComplain: async ({ id, message }, onDone) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.put(`/admin/resolve-complain`, {
        id,
        message,
      });
      if (response.status === 200) {
        get().getPendingComplains();
        toast.success('Complain resolved');
        set(() => ({ isUpdating: false }));
        onDone();
      }
    } catch (error) {
    } finally {
      set(() => ({ isUpdating: false }));
    }
  },

  getPendingComplains: async (page) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(`admin/pending-complains`);
      if (response.status === 200) {
        const complainsData = response.data.data;
        set(() => ({ complainsData, isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
