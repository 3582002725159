import { Stack } from '@mui/material';
import React from 'react';

const LogoWithName = () => {
  return (
    <Stack my={2}>
      <img src="/images/logo.png" alt="logo" height={200} width={200} />
    </Stack>
  );
};

export default LogoWithName;
