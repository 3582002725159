import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Typography } from '@mui/material';
import LogoWithName from 'components/common/LogoWithName';
import OTPCountdown from 'components/otp-countdown/OTPCountdown';
import { useAuth } from 'hooks/useAuth';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const VerifyOTPPage = () => {
  const { state } = useLocation();
  const phone = get(state, 'phone', '');
  const userId = get(state, 'userId', '');

  const [otp, setOtp] = useState('');
  const { isLoading, verifyOTP } = useAuth();

  const onSubmit = () => {
    if (otp.length !== 6) {
      return toast.error('Please enter 6 digit OTP');
    }
    const formValue = { userId, otp };
    verifyOTP(formValue);
  };

  const handleOTPChange = (e) => {
    if (e.target.value.length <= 6) {
      setOtp(e.target.value);
    }
  };

  return (
    <Stack alignItems={'center'} justifyContent="center" mt={10} spacing={2}>
      <LogoWithName />
      <Typography color="gray">
        We have sent your +88{phone} a 6 digit OTP into your phone number.
      </Typography>
      <TextField
        autoFocus
        required
        fullWidth
        id="outlined-required"
        label="OTP"
        type="number"
        value={otp}
        onChange={handleOTPChange}
      />
      <OTPCountdown />
      <LoadingButton
        onClick={onSubmit}
        fullWidth
        variant="contained"
        loading={isLoading}
      >
        Complete Login
      </LoadingButton>
    </Stack>
  );
};

export default VerifyOTPPage;
