import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useComplain = create((set) => ({
  isLoading: false,
  complains: [],

  createComplain: async (formValue) => {
    set(() => ({ isLoading: true }));
    try {
      await apiClient.post('complain', { ...formValue });
      toast.success('Complain created');
      set(() => ({ isLoading: false }));
    } catch (error) {
      set(() => ({ isLoading: false }));
    }
  },

  getMyComplains: async () => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('complain');
      set(() => ({ isLoading: false, complains: response.data.data }));
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
