import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { blue, green, red } from '@mui/material/colors';
import React from 'react';

import {
  Alert,
  Avatar,
  Card,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingSpinner } from 'components';
import { format } from 'date-fns';
import { useTransfer } from 'hooks/useTransfer';

const HistoryPage = () => {
  const [value, setValue] = React.useState('1');
  const { transactionHistory, getUserTransactions, isLoading } = useTransfer();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getUserTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        Payment History
      </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="All" value="1" />
            <Tab label="Pending" value="2" />
            <Tab label="Success" value="3" />
            <Tab label="Failed" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <HistoryList histories={transactionHistory} />
        </TabPanel>
        <TabPanel value="2">
          <HistoryList
            histories={transactionHistory?.filter(
              (hist) => hist.status === 'pending'
            )}
          />
        </TabPanel>
        <TabPanel value="3">
          <HistoryList
            histories={transactionHistory?.filter(
              (hist) => hist.status === 'success'
            )}
          />
        </TabPanel>
        <TabPanel value="4">
          <HistoryList
            histories={transactionHistory?.filter(
              (hist) => hist.status === 'failed'
            )}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default HistoryPage;

function HistoryList({ histories = [] }) {
  if (histories?.length === 0) {
    return <Alert severity="error">No histories</Alert>;
  }

  return (
    <div>
      {histories.map((history) => {
        const { amount, createdAt, method, status, type, _id, comment } =
          history;
        const date = format(new Date(createdAt), 'd-MM-yy p');
        const isDeposit = type === 'deposit' || type === 'bonus';
        return (
          <Card
            key={_id}
            sx={{
              marginBottom: 3,
              border: '1px solid #ddd',
              borderColor: status === 'failed' ? 'red' : '#ccc',
            }}
          >
            <Typography pt={2} pl={3} fontWeight={'500'} color={blue[900]}>
              {isDeposit ? '+' : '-'} ৳{amount}
            </Typography>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: isDeposit ? green[500] : red[500] }}
                  aria-label="recipe"
                >
                  <Typography variant="h5">{isDeposit ? '+' : '-'}</Typography>
                </Avatar>
              }
              action={
                <Typography
                  sx={{
                    padding: '2px 12px',
                    fontSize: 13,
                    border: '1px solid #ddd',
                    borderRadius: 10,
                    color: '#fff',
                    backgroundColor:
                      status === 'pending'
                        ? '#ff9100'
                        : status === 'success'
                        ? '#43a047'
                        : '#d32f2f',
                  }}
                >
                  {status}
                </Typography>
              }
              title={`${method?.title || `${history.transId}`}  ${
                method?.accountNumber || ''
              }`}
              subheader={
                <Stack mt={2} spacing={1}>
                  <Typography>Date: {date}</Typography>
                  {comment && (
                    <Alert severity="warning">Comment: {comment}</Alert>
                  )}
                </Stack>
              }
            />
          </Card>
        );
      })}
    </div>
  );
}
