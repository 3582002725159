import { Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const OTPCountdown = ({ hideLogin, onCountEnd }) => {
  const [seconds, setSeconds] = useState(50);

  useEffect(() => {
    let myInterval = window.setInterval(() => {
      if (seconds > 0) {
        setSeconds((ps) => ps - 1);
      }
      if (seconds === 0) {
        setSeconds(0);
        onCountEnd();
        return window.clearInterval(myInterval);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  return (
    <Stack alignItems="center" my={2} spacing={1}>
      {seconds > 9 && (
        <Typography fontWeight="400">
          OTP expires in
          <Typography component="span" color="red" fontWeight={600}>
            {' '}
            {seconds}
          </Typography>{' '}
          seconds
        </Typography>
      )}
      {seconds === 0 && (
        <Typography component="span" color="red" fontWeight={600}>
          OTP Expired!
        </Typography>
      )}
      {seconds === 0 && !hideLogin && <Link href="/login">Login Again</Link>}
    </Stack>
  );
};

export default OTPCountdown;
