import { Button, Card, Stack, Typography } from '@mui/material';
import React from 'react';

const Pagination = ({ isLoading, page, totalPage, onPrev, onNext }) => {
  const prevDisabled = page === 1 || isLoading;
  const nextDisabled = page === totalPage || isLoading;
  return (
    <Card
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <Stack
        px={10}
        py={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button disabled={prevDisabled} onClick={onPrev}>
          Prev
        </Button>
        <Typography>
          Page {page} of {totalPage}
        </Typography>
        <Button disabled={nextDisabled} onClick={onNext}>
          Next
        </Button>
      </Stack>
    </Card>
  );
};

export default Pagination;
