import {
  Alert,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingSpinner } from 'components';
import { useAdminTransaction } from 'hooks/useAdminTransaction';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Pagination from 'components/common/Pagination';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useClipboard } from 'use-clipboard-copy';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';

const PendingDepositPage = () => {
  const clipboard = useClipboard();
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    getAllPendingDeposits,
    depositData,
    isLoading,
    rejectTransaction,
    approveTransaction,
    isUpdating,
  } = useAdminTransaction();
  const [page, setPage] = useState(1);
  const [selectedForRemove, setSelectedForRemove] = useState('');

  console.log({ depositData });

  useEffect(() => {
    getAllPendingDeposits(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getAllPendingDeposits(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCopyAcc(acc) {
    clipboard.copy(acc);
    window?.ReactNativeWebView?.postMessage(acc);
    toast.success('Copied');
  }

  const totalPage = get(depositData, 'totalPages', '...');
  const onPrev = () => {
    setPage((prev) => prev - 1);
  };
  const onNext = () => {
    setPage((prev) => prev + 1);
  };

  function handleApprove(uid) {
    if (window.confirm('Are you sure to Approve this transaction ')) {
      console.log(uid);
      approveTransaction(uid, () => setPage(1));
    }
  }

  function handleReject(uid) {
    setSelectedForRemove(uid);
    handleOpen();
  }

  const handleRejectReq = () => {
    if (!comment) return alert('Please add a comment');
    rejectTransaction(selectedForRemove, comment, () => setPage(1));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form style={{ width: '100%' }} onSubmit={handleRejectReq}>
            <Stack
              sx={{ width: '100%' }}
              direction="column"
              spacing={2}
              component="from"
            >
              <Typography>Reject the Transaction</Typography>
              <TextField
                required
                id="outlined-required"
                label="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <LoadingButton
                type="submit"
                loading={false}
                variant="contained"
                color="error"
              >
                Reject
              </LoadingButton>
              <LoadingButton
                type="submit"
                loading={false}
                variant="contained"
                color="success"
                onClick={handleClose}
              >
                Cancel
              </LoadingButton>
            </Stack>
          </form>
        </Box>
      </Modal>
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        Pending Deposits
      </Typography>
      {(isLoading || isUpdating) && <LoadingSpinner />}
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          paddingBottom: 50,
          bgcolor: 'background.paper',
        }}
      >
        {depositData?.deposits &&
          depositData?.deposits.map((deposit) => (
            <ListItem
              key={deposit._id}
              secondaryAction={
                <Stack direction="row" alignItems="center">
                  <IconButton onClick={() => handleReject(deposit._id)}>
                    <CancelIcon color="error" fontSize="medium" />
                  </IconButton>
                  <IconButton onClick={() => handleApprove(deposit._id)}>
                    <CheckCircleIcon color="success" fontSize="medium" />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemText
                primary={`Amount: ৳${deposit?.amount}`}
                secondary={
                  <>
                    <Typography>
                      {deposit?.user?.name} ({deposit?.user?.userId})
                    </Typography>
                    <Typography>
                      Time:{' '}
                      {format(new Date(deposit?.createdAt), 'dd-mm-yy hh:mma')}
                    </Typography>
                    <Typography>Method: {deposit?.method?.title}</Typography>
                    <Typography>
                      Account No.: {deposit?.method?.accountNumber}
                      <IconButton
                        onClick={() =>
                          onCopyAcc(deposit?.method?.accountNumber)
                        }
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Typography>
                    <Typography></Typography>
                    <Typography>TraxId: {deposit?.transId}</Typography>
                  </>
                }
              />
            </ListItem>
          ))}
      </List>
      {!isLoading && depositData?.deposits?.length === 0 && (
        <Stack alignItems="center">
          <Alert severity="success">No pending deposits</Alert>
        </Stack>
      )}
      <Pagination
        isLoading={isLoading}
        page={page}
        totalPage={totalPage}
        onPrev={onPrev}
        onNext={onNext}
      />
    </>
  );
};

export default PendingDepositPage;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
