import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Stack, TextField, Typography } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { useProfileSettings } from 'hooks/useProfileSettings';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import ChangePhone from 'components/change-phone/ChangePhone';
import { useLocation } from 'react-router-dom';
import { TAB_VALUES } from 'utils/constants';

const ProfileSettingsPage = () => {
  const { state } = useLocation();

  console.log(state);

  const [value, setValue] = useState(TAB_VALUES.PASS_CHANGE);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { profile } = useAuth();
  console.log({ profile });
  const { isLoading, updateProfile, updatePassword } = useProfileSettings();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const onUpdateProfile = () => {
    if (!name || !email) {
      return toast.error('Please enter name and email');
    }
    updateProfile({ name, email });
  };

  useEffect(() => {
    if (state?.value) {
      setValue(state.value);
    }
  }, [state]);

  const onUpdatePassword = () => {
    if (!password || !newPassword || !confirmPassword) {
      return toast.error('Please enter passwords');
    }
    if (newPassword.length !== confirmPassword.length) {
      return toast.error('password does not match');
    }
    if (newPassword.length < 5) {
      return toast.error('password should at least 5 characters long');
    }
    updatePassword({ password, newPassword });
  };

  useEffect(() => {
    if (profile.name) {
      setName(profile.name);
    }
    if (profile.email) {
      setEmail(profile.email);
    }
  }, []);

  return (
    <div>
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        Profile Settings
      </Typography>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="settings">
              <Tab label="Change Pass" value={TAB_VALUES.PASS_CHANGE} />
              <Tab label="Change Phone" value={TAB_VALUES.PHONE_CHANGE} />
              <Tab label="Change Name/Email" value={TAB_VALUES.NAME_CHANGE} />
            </TabList>
          </Box>
          <TabPanel value={TAB_VALUES.PASS_CHANGE}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                label="Current Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                fullWidth
                label="New password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                fullWidth
                label="Re-enter New password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={onUpdatePassword}
              >
                Change
              </LoadingButton>
            </Stack>
          </TabPanel>
          <TabPanel value={TAB_VALUES.NAME_CHANGE}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                label="Name"
                defaultValue={name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Email"
                defaultValue={email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={onUpdateProfile}
              >
                Change
              </LoadingButton>
            </Stack>
          </TabPanel>
          <TabPanel value={TAB_VALUES.PHONE_CHANGE}>
            <Typography py={2} textAlign="center">
              Your current phone - {profile.phone}
            </Typography>
            <ChangePhone />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default ProfileSettingsPage;
