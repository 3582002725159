import apiClient from 'api/apiClient';
import create from 'zustand';

export const useAdminDashboard = create((set, get) => ({
  isLoading: false,
  dashboardData: {},

  getDashboardData: async (page) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(`admin/dashboard-data`);
      if (response.status === 200) {
        const dashboardData = response.data.data;
        set(() => ({ dashboardData, isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
