import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { Alert, Divider, Stack, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { LoadingSpinner } from 'components';
import { format } from 'date-fns';
import { useAdminTransaction } from 'hooks/useAdminTransaction';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'utils/appRoutes';

const TodaysTransactionsPage = () => {
  const [totalIn, setTotalIn] = useState(0);
  const [totalOut, setTotalOut] = useState(0);
  const [date, setDate] = useState(new Date());

  const { isLoading, getTodaysTransactions, todaysTransactions } =
    useAdminTransaction();

  const navigate = useNavigate();

  console.log({ isLoading, todaysTransactions });

  useEffect(() => {
    getTodaysTransactions(date.toISOString());
  }, [date]);

  const goToUserDetail = (user) => {
    navigate(appRoutes.ADMIN_USER_DETAILS, {
      state: { user },
    });
  };

  useEffect(() => {
    console.log(todaysTransactions);

    if (todaysTransactions?.length === 0) return;

    const total = todaysTransactions
      .filter((item) => item.type === 'deposit')
      .map((item) => parseFloat(item.amount))
      ?.reduce((prev, next) => prev + next, 0);
    setTotalIn(total);

    const total2 = todaysTransactions
      .filter((item) => item.type === 'withdraw')
      .map((item) => parseFloat(item.amount))
      ?.reduce((prev, next) => prev + next, 0);
    setTotalOut(total2);
  }, [todaysTransactions]);

  return (
    <Container>
      {isLoading && <LoadingSpinner />}
      <Stack direction="row" alignItems="center" spacing={4}>
        <Typography
          color="primary"
          textAlign={'center'}
          fontWeight="500"
          py={2}
        >
          Transactions of : {format(date, 'dd MMMM yyyy')}
        </Typography>
        <DatePicker selected={date} onChange={(date) => setDate(date)} />
      </Stack>

      <Alert sx={{ mb: 2 }} severity="info">
        Total Deposit - {totalIn} | Total withdraw - {totalOut}
      </Alert>

      {todaysTransactions.map((trns) => {
        if (trns.type === 'deposit' || trns.type === 'withdraw')
          return (
            <Box
              key={trns._id}
              sx={{ cursor: 'pointer' }}
              onClick={() => goToUserDetail(trns.user)}
              mb={2}
            >
              <Stack direction="row" justifyContent="space-between">
                <div>
                  <Typography
                    variant="h5"
                    color={trns.type === 'withdraw' ? 'red' : 'green'}
                    fontWeight={500}
                  >
                    {trns.amount} taka ({trns.type})
                  </Typography>
                  <Typography>
                    User - {trns.user.name} ({trns.user.userId})
                  </Typography>
                  <Typography>Trnx Id - {trns.transId}</Typography>
                  <Typography>User balance - {trns.user.balance}/-</Typography>
                  <Typography>
                    Date - {format(new Date(trns.createdAt), 'dd-MM-yy hh:mma')}
                  </Typography>
                </div>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  spacing={1}
                >
                  <Typography fontSize="13px">User details</Typography>
                  <KeyboardTabIcon />
                </Stack>
              </Stack>
              <Divider sx={{ marginTop: 1 }} />
            </Box>
          );
      })}
    </Container>
  );
};

export default TodaysTransactionsPage;
