import {
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingSpinner } from 'components';
import { useAdminTransaction } from 'hooks/useAdminTransaction';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Pagination from 'components/common/Pagination';
import { format } from 'date-fns';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { vi } from 'date-fns/esm/locale';
import { useClipboard } from 'use-clipboard-copy';
import { toast } from 'react-toastify';

const PendingWithdrawPage = () => {
  const clipboard = useClipboard();

  const {
    getAllPendingWithdraws,
    withdrawData,
    isLoading,
    rejectTransaction,
    approveTransaction,
    isUpdating,
  } = useAdminTransaction();
  const [page, setPage] = useState(1);

  useEffect(() => {
    getAllPendingWithdraws(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const totalPage = get(withdrawData, 'totalPages', '...');
  const onPrev = () => {
    setPage((prev) => prev - 1);
  };
  const onNext = () => {
    setPage((prev) => prev + 1);
  };

  function handleApprove(uid) {
    if (window.confirm('Are you sure make this transaction as done')) {
      console.log(uid);
      approveTransaction(uid, () => setPage(1));
    }
  }
  function onCopyAcc(acc) {
    clipboard.copy(acc);
    toast.success('Copied');
  }

  function handleReject(uid) {
    if (window.confirm('Are you sure to reject this transaction?')) {
      console.log(uid);
      rejectTransaction(uid, () => setPage(1));
    }
  }

  return (
    <>
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        Pending withdrawals
      </Typography>
      {(isLoading || isUpdating) && <LoadingSpinner />}
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          paddingBottom: 50,
          bgcolor: 'background.paper',
        }}
      >
        {withdrawData?.withdraws &&
          withdrawData?.withdraws.map((withdraw) => (
            <ListItem
              key={withdraw._id}
              secondaryAction={
                <Stack direction="row" alignItems="center">
                  <IconButton onClick={() => handleReject(withdraw._id)}>
                    <CancelIcon color="error" fontSize="medium" />
                  </IconButton>
                  <IconButton onClick={() => handleApprove(withdraw._id)}>
                    <CheckCircleIcon color="success" fontSize="medium" />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemText
                primary={`Amount: ৳${withdraw?.amount}`}
                secondary={
                  <>
                    <Typography>
                      {withdraw?.user?.name} ({withdraw?.user?.userId})
                    </Typography>
                    <Typography>
                      Time:{' '}
                      {format(new Date(withdraw?.createdAt), 'dd-mm-yy hh:mma')}
                    </Typography>
                    <Typography>Method: {withdraw?.method?.title}</Typography>
                    <Typography>
                      Account No.: {withdraw?.method?.accountNumber}
                      <IconButton
                        onClick={() =>
                          onCopyAcc(withdraw?.method?.accountNumber)
                        }
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Typography>
                    <Typography>
                      {format(new Date(withdraw?.createdAt), 'dd-mm-yy hh:mma')}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
      </List>
      {!isLoading && withdrawData?.withdraws?.length === 0 && (
        <Stack alignItems="center">
          <Alert severity="success">No pending withdrawals</Alert>
        </Stack>
      )}
      <Pagination
        isLoading={isLoading}
        page={page}
        totalPage={totalPage}
        onPrev={onPrev}
        onNext={onNext}
      />
    </>
  );
};

export default PendingWithdrawPage;
