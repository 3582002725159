import { LoadingSpinner } from 'components';
import AdminLayout from 'components/layouts/AdminLayout';
import { useAuth } from 'hooks/useAuth';
import { useSettings } from 'hooks/useSettings';
import { usePaymentMethods } from 'hooks/useUserMethods';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectAdmin = () => {
  const { isLoggedIn, role, getUserProfile, authLoading } = useAuth();
  const {
    getAppSettings,
    isLoading: settingsLoading,
    getUserSettings,
  } = useSettings();
  const { isLoading: methodsLoading, getPaymentMethods } = usePaymentMethods();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getUserProfile();
    getPaymentMethods();
    getAppSettings();
    getUserSettings();
    setLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn || role !== 'admin') {
    return <Navigate to="/login" />;
  }

  if (!loaded || authLoading || methodsLoading || settingsLoading) {
    return <LoadingSpinner />;
  }
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};
export default ProtectAdmin;
