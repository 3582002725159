import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import { useAdmin } from 'hooks/useAdmin';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddUserPage = () => {
  const { addNewUser, isLoading } = useAdmin();

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [ownCode, setownCode] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [bonusPercent, setBonusPercent] = useState('');
  const [isAff, setIsAff] = useState(false);

  function handleSubmit() {
    if (!name || !userId || !password || !role || !phone) {
      return toast.error('userId, name, password, role and phone Required');
    }
    if (phone.length !== 11) {
      return toast.error('11 digit phone');
    }

    if (password.length < 5) {
      return toast.error('Password should be minimum 5 characters');
    }

    if (isAff && !ownCode) {
      return toast.error('Affiliate need own code');
    }

    const formValues = {
      name,
      userId,
      ownCode,
      referralCode,
      password,
      role,
      email,
      phone,
      bonusPercent,
    };
    addNewUser(formValues, navigate);
  }

  return (
    <Container>
      <Typography color="primary" textAlign={'center'} fontWeight="500" py={2}>
        New user form
      </Typography>

      <Stack spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            defaultValue="user"
            onChange={(e) => setRole(e.target.value)}
          >
            <MenuItem value={'user'}>User</MenuItem>
            <MenuItem value={'stuff'}>Stuff</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="User name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label="User id"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
        <TextField
          fullWidth
          label="email (optional)"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Phone no. (11 digit)"
          value={phone}
          type="number"
          onChange={(e) => setPhone(e.target.value)}
        />

        <TextField
          fullWidth
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <TextField
          fullWidth
          label="Referral Code (refer from)"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={isAff} onChange={() => setIsAff((p) => !p)} />
            }
            label="Affiliate"
          />
        </FormGroup>

        {isAff && (
          <>
            <TextField
              fullWidth
              label="Affiliate code (own)"
              value={ownCode}
              onChange={(e) => setownCode(e.target.value)}
            />

            <TextField
              fullWidth
              label="Bonus Percent (Ex - 10,30)"
              value={bonusPercent}
              onChange={(e) => setBonusPercent(e.target.valueAsNumber)}
              type="number"
            />
          </>
        )}

        <LoadingButton
          loading={isLoading}
          onClick={handleSubmit}
          variant="contained"
        >
          Add User
        </LoadingButton>
      </Stack>
    </Container>
  );
};

export default AddUserPage;
