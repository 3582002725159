import apiClient from 'api/apiClient';
import create from 'zustand';

export const userUserSearch = create((set, get) => ({
  isLoading: false,
  users: {},

  fetchUsers: async (query) => {
    set(() => ({ isLoading: true, users: [] }));
    try {
      const response = await apiClient.get(`admin/search-users/${query}`);
      if (response.status === 200) {
        const users = response.data.data;
        set(() => ({ users, isLoading: false }));
      } else {
        set(() => ({ users: [], isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
}));
