import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { usePaymentMethods } from 'hooks/useUserMethods';
import React, { useEffect, useState } from 'react';
import { getMethodIconByName } from 'utils/helpers';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'utils/appRoutes';
import UserWithdraw from 'components/payment-methods/UserWithdraw';
import { LoadingSpinner } from 'components';

const WithdrawPage = () => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const navigate = useNavigate();
  const {
    myMethods,
    getMyMethods,
    isLoading: methodsLoading,
  } = usePaymentMethods();

  useEffect(() => {
    if (!myMethods) {
      getMyMethods();
    }
  }, []);

  function goToAdd() {
    navigate(appRoutes.PAYMENT_METHODS);
  }

  if (methodsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {selectedMethod && (
        <UserWithdraw
          selectedMethod={selectedMethod}
          onClose={() => setSelectedMethod(null)}
        />
      )}
      <Typography
        color="gray"
        textAlign={'center'}
        fontWeight="500"
        variant="h6"
        m={2}
        pb={1}
      >
        Withdraw/CashOut Money
      </Typography>
      <Typography color="gray" ml={3} mb={2}>
        Select A method or<Button onClick={goToAdd}>Add new method</Button>
      </Typography>
      <List sx={{ padding: '5px 12px' }}>
        {myMethods.map((method) => (
          <MethodCard
            onClick={setSelectedMethod}
            key={method._id}
            method={method}
          />
        ))}
      </List>
    </div>
  );
};

export default WithdrawPage;

const MethodCard = ({ method, onClick }) => {
  const accountNumber = get(method, 'accountNumber', '');
  const title = get(method, 'title', '');
  const name = get(method, 'name', '');

  const image = getMethodIconByName(name);

  return (
    <ListItem sx={{ cursor: 'pointer' }} onClick={() => onClick(method)}>
      <ListItemAvatar>
        <Avatar src={image} alt={name} sx={{ width: 48, height: 48 }} />
      </ListItemAvatar>
      <ListItemText
        sx={{ marginLeft: 1 }}
        primary={title}
        secondary={accountNumber}
      />
    </ListItem>
  );
};
