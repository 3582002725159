import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { DepositForm } from 'components';
import { usePaymentMethods } from 'hooks/useUserMethods';
import { uniqBy } from 'lodash';
import React, { useState } from 'react';
import { getMethodIconByName } from 'utils/helpers';

export const DepositNowPage = () => {
  const { paymentMethods } = usePaymentMethods();

  const [formVisible, setFormVisible] = useState(false);
  const [methodName, setMethodName] = useState(null);

  function closeForm() {
    setFormVisible(false);
  }

  const uniqueMethods = uniqBy(paymentMethods, 'name');
  console.log({ paymentMethods });

  function handleClick(method) {
    setMethodName(method?.name);
    setFormVisible(true);
  }

  return (
    <div>
      <DepositForm
        isVisible={formVisible}
        methodName={methodName}
        onClose={closeForm}
      />
      <Typography
        color="gray"
        textAlign={'center'}
        fontWeight="500"
        variant="h6"
        m={2}
        pb={1}
        sx={{ borderBottom: '1px solid #ddd' }}
      >
        Online Payment
      </Typography>
      <Grid container sx={{ margin: '0 auto', pb: 4 }} justifyContent="center">
        {uniqueMethods.map((method) => (
          <Grid
            onClick={() => handleClick(method)}
            xs={5}
            m={1}
            item
            key={method._id}
          >
            <Card sx={{ cursor: 'pointer' }}>
              <Stack alignItems="center">
                <img
                  component="img"
                  height="100px"
                  width="100px"
                  style={{ objectFit: 'contain' }}
                  src={getMethodIconByName(method.name)}
                  alt="green iguana"
                />
              </Stack>
              <CardContent>
                <Typography textAlign={'center'} fontWeight="500">
                  {method.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
