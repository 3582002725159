import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import create from 'zustand';

export const useAdminTransaction = create((set, get) => ({
  isLoading: false,
  depositData: {},
  withdrawData: {},
  todaysTransactions: [],
  isUpdating: false,

  getAllPendingDeposits: async (page) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(
        `admin/pending-deposits?page=${page}&limit=10`
      );
      if (response.status === 200) {
        const depositData = response.data.data;
        set(() => ({ depositData, isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  getTodaysTransactions: async (date) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(`admin/todays-trans/${date}`);
      if (response.status === 200) {
        const allData = response.data.data;
        set(() => ({ todaysTransactions: allData, isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  getAllPendingWithdraws: async (page) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get(
        `admin/pending-withdraws?page=${page}&limit=10`
      );
      if (response.status === 200) {
        const withdrawData = response.data.data;
        set(() => ({ withdrawData, isLoading: false }));
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  approveTransaction: async (uid, onDone) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.put(`/transaction`, {
        id: uid,
        status: 'success',
      });
      if (response.status === 200) {
        get().getAllPendingDeposits();
        get().getAllPendingWithdraws();
        toast.success('Success');
        set(() => ({ isUpdating: false }));
        onDone();
      }
    } catch (error) {
    } finally {
      set(() => ({ isUpdating: false }));
    }
  },

  rejectTransaction: async (uid, comment, onDone) => {
    set(() => ({ isUpdating: true }));
    try {
      const response = await apiClient.put(`/transaction`, {
        id: uid,
        status: 'failed',
        comment,
      });
      if (response.status === 200) {
        get().getAllPendingDeposits();
        get().getAllPendingWithdraws();
        toast.success('Rejected');
        set(() => ({ isUpdating: false }));
        onDone();
      }
    } catch (error) {
    } finally {
      set(() => ({ isUpdating: false }));
    }
  },
}));
