import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useSettings } from 'hooks/useSettings';
import { useTransfer } from 'hooks/useTransfer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const UserReferralWithdraw = ({ selectedMethod, onClose }) => {
  const [takaAmount, setTakaAmount] = useState('');
  const [coinAmount, setCoinAmount] = useState('');

  const { userSettings } = useSettings();
  console.log({ userSettings });
  const { profile } = useAuth();
  const { createReferralWithdraw, isLoading } = useTransfer();

  const availableBalance = parseFloat(
    profile.affTotal - profile.affPaid
  ).toFixed(2);

  const navigator = useNavigate();

  const onChangeTaka = (e) => {
    setCoinAmount('');
    setTakaAmount('');
    const amountInt = parseFloat(e.target.value);
    if (amountInt > 0) {
      const rate = amountInt / parseFloat(userSettings?.coinRate);
      setCoinAmount(rate.toFixed(2).toString());
    } else {
      setCoinAmount('');
    }
    setTakaAmount(e.target.value);
  };

  function handleWithdraw() {
    if (takaAmount < 300) {
      return toast.error(`The minimum amount should be 300!`);
    }

    if (takaAmount > userSettings.withdrawLimit) {
      return toast.error(
        `You can withdraw ${userSettings.withdrawLimit} taka at at time!`
      );
    }

    console.log({ takaAmount, availableBalance });
    if (parseFloat(takaAmount) > availableBalance) {
      return toast.error(`Your payable balance is: ${availableBalance} taka`);
    }

    if (!setTakaAmount) {
      return toast.error('Fill all the fields');
    }
    if (userSettings.allowRefWithdraw === false) {
      return toast.error('You can not withdraw today');
    }
    const formValues = {
      method: selectedMethod._id,
      coin: coinAmount,
      amount: takaAmount,
      rate: userSettings.coinRate,
    };
    createReferralWithdraw(formValues, navigator);
  }

  return (
    <div>
      <Modal open={!!selectedMethod} onClose={onClose}>
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography id="modal-modal-title" component="h2">
              Withdraw to your {selectedMethod.title} Account No.-{' '}
              {selectedMethod.accountNumber}
            </Typography>
            <Typography gutterBottom>
              You have:
              <Typography variant="span" fontWeight="bold" color="green">
                {' '}
                {availableBalance} /-
              </Typography>
            </Typography>
            <TextField
              fullWidth
              label="Amount in Taka"
              value={takaAmount}
              type="number"
              onChange={onChangeTaka}
            />
            <LoadingButton
              loading={isLoading}
              onClick={handleWithdraw}
              variant="contained"
              fullWidth
              sx={{ my: 2 }}
            >
              Create withdraw request
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default UserReferralWithdraw;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
