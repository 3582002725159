import apiClient from 'api/apiClient';
import { toast } from 'react-toastify';
import { AlertHelper } from 'utils/alert';
import appRoutes from 'utils/appRoutes';
import create from 'zustand';

export const useTransfer = create((set, get) => ({
  isLoading: false,
  transactionHistory: [],

  /* Deposit */
  createTransaction: async (formValues, navigate) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('transaction', {
        ...formValues,
        type: 'deposit',
      });
      if (response.status === 201) {
        toast.success('Payment request sent successfully.');
        set(() => ({ isLoading: false }));
        get().getUserTransactions();
        navigate(appRoutes.HISTORY);
      }
    } catch (error) {
      console.log('Failed to create deposit request');
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  createWithdraw: async (formValues, navigator) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('transaction', {
        ...formValues,
        type: 'withdraw',
      });
      if (response.status === 201) {
        AlertHelper.show(
          'success',
          'Done',
          'Withdraw request sent successfully.'
        );
        set(() => ({ isLoading: false }));
        navigator(appRoutes.HISTORY);
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  createReferralWithdraw: async (formValues, navigator) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.post('transaction', {
        ...formValues,
        type: 'referral',
      });
      if (response.status === 201) {
        AlertHelper.show(
          'success',
          'Done',
          'Withdraw request sent successfully.'
        );
        set(() => ({ isLoading: false }));
        navigator(appRoutes.HISTORY);
      }
    } catch (error) {
    } finally {
      set(() => ({ isLoading: false }));
    }
  },

  /* Get all */
  getUserTransactions: async (formValues) => {
    set(() => ({ isLoading: true }));
    try {
      const response = await apiClient.get('transaction');
      if (response.status === 200) {
        set(() => ({
          isLoading: false,
          transactionHistory: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      set(() => ({ isLoading: false }));
    }
  },
  /* Change Status (admin only) */
}));
