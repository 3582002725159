import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import OTPVerify from 'components/otp-verify/OTPVerify';
import { useProfileSettings } from 'hooks/useProfileSettings';

const ChangePhone = () => {
  const [newPhone, setNewPhone] = useState('');
  const [otp, setOtp] = useState('');

  const { updateUserPhone, isLoading } = useProfileSettings();

  function handleSubmit() {
    if (newPhone.length !== 11 || otp.length !== 6)
      return toast.error('11 digit phone no. and 6 digit otp needed');
    updateUserPhone({ phone: newPhone, otp });
  }

  return (
    <div>
      <Stack spacing={1}>
        <TextField
          fullWidth
          label="New phone no. (11 digit)"
          value={newPhone}
          onChange={(e) => setNewPhone(e.target.value)}
        />
        <OTPVerify otp={otp} setOtp={setOtp} />
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          Change
        </LoadingButton>
      </Stack>
    </div>
  );
};

export default ChangePhone;
